import type { FC } from 'react';
import type { GetStaticProps, NextPage } from 'next';
import type { PageProps, PageLayoutProps } from './[...slug]';
import type { ContentType } from '@api/hooks/types';
import { SWRConfig } from 'swr';
import { HeadBase } from '@components/Layout/components/Head/Head';
import { PageFlow } from '@components/Layout';
import { ProgressBarPage } from '@core';
import { getFooterData, getHeaderData, getPageData, usePageData } from '@api';
import { prepareApiUrl } from '@utils';

const needMock = false;

const HomeLayout: FC<PageLayoutProps> = ({ endpoint = null }) => {
	/*
	 * Получение данных через SWR (из контекста или запросом)
	 * Подробнее: https://swr.vercel.app/ru/docs/with-nextjs
	 */
	const { data } = usePageData(endpoint, needMock);

	return (
		<>
			<HeadBase {...data?.meta} />
			<PageFlow {...data} />
			<ProgressBarPage />
		</>
	);
};

const Home: NextPage<PageProps> = ({ fallback, endpoint }) => {
	return (
		<SWRConfig value={{ fallback }}>
			<HomeLayout endpoint={endpoint} />
		</SWRConfig>
	);
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
	try {
		const endpoint = prepareApiUrl('/', locale, '/pages');
		const header = prepareApiUrl('/menu/header', locale);
		const footer = prepareApiUrl('/menu/footer', locale);

		/*
		 * Получение страницы (данные, мета-описание), а также данные шапки и подвала
		 */
		const pageData = await getPageData(endpoint, needMock);
		const headerData = await getHeaderData(header, needMock);
		const footerData = await getFooterData(footer, true);

		/*
		 * Если данных нет, редирект на 404
		 */
		if (!pageData || !headerData || !footerData) {
			return { notFound: true, revalidate: 2 };
		}

		/*
		 * Получение данных поблочно, если есть hypermedia-ссылки
		 * Например, блок новостей на странице событий { 'events/news': [...] }
		 */
		const endpoints: Record<string, any> = {};

		if (pageData?.data) {
			(pageData.data as Array<ContentType>)
				.filter((item) => item.links)
				.forEach((item) => {
					if (item?.links?.self) {
						endpoints[item.links.self] = item.content.items || {};
					}
				});
		}

		return {
			props: {
				/*
				 * Проброс fallback-параметра для SWR по всем полученным данным
				 */
				fallback: {
					[header]: headerData || {},
					[footer]: footerData || {},
					[endpoint]: pageData,
					...endpoints,
				},
				/*
				 * Проброс эндпоинта страницы
				 */
				endpoint,
				/*
				 * Отдельно передаю meta, чтобы получить в LayoutDefault
				 */
				meta: pageData?.meta,
			},
			revalidate: 10,
		};
	} catch (error) {
		return { notFound: true, revalidate: 2 };
	}
};

export default Home;
