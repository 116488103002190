import type { PageMeta } from '@api/hooks/types';
import React, { FC, PropsWithChildren } from 'react';
import Head from 'next/head';

export const HeadBase: FC<PropsWithChildren<PageMeta>> = ({
	title = 'Fensma',
	description = '',
	metaTags = [],
	children,
}) => {
	return (
		<Head>
			<title>{title}</title>

			<meta name="description" content={description} />
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"
			/>

			{metaTags?.map(({ content, name, nameType }, i) => {
				const props = nameType
					? {
							[nameType]: name,
					  }
					: {};
				return <meta key={`${name}-${content}`} content={content} {...props} />;
			})}

			<link rel="icon" href="/favicons/32_32.png" type="image/x-icon" />
			<link rel="shortcut icon" href="/favicons/32_32.png" type="image/x-icon" />
			<link rel="apple-touch-icon" sizes="76x76" href="/favicons/76_76.png" />
			<link rel="apple-touch-icon" sizes="120x120" href="/favicons/120_120.png" />
			<link rel="apple-touch-icon" sizes="152x152" href="/favicons/152_152.png" />
			<link rel="apple-touch-icon" sizes="180x180" href="/favicons/180_180.png" />

			{children}
		</Head>
	);
};
